<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex lg12>
        <v-card tile flat>
          <v-layout wrap justify-start pa-4>
            <v-flex xs12 pt-6 pb-4 align-self-center text-left>
              <span style="font-family: poppinsbold; font-size: 20px">
                Edit Unit
              </span>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Name </span>

              <v-text-field
                outlined
                v-model="unitData.name"
                hide-details
                placeholder="Name"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold">Select Type</span>

              <v-select
                hide-details
                v-model="unitData.type"
                :items="types"
                dense
                placeholder="Select Type"
                outlined
              ></v-select>
            </v-flex>

            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type != 'Ic' && unitData.type != 'Entry Point'"
            >
              <span style="font-family: poppinsbold">Select IC</span>

              <v-select
                hide-details
                v-model="unitData.icId"
                :items="icList"
                item-text="name"
                item-value="_id"
                dense
                placeholder="Select IC"
                outlined
              ></v-select>
            </v-flex>
            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type == 'Transport Vehicle'"
            >
              <span style="font-family: poppinsbold"> No. of Seats </span>

              <v-text-field
                outlined
                v-model="unitData.noOfSeats"
                hide-details
                type="number"
                placeholder="No. of Seats"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex
              xs12
              sm6
              pa-2
              align-self-start
              text-left
              v-if="unitData.type == 'Transport Vehicle'"
            >
              <span style="font-family: poppinsbold"> Register Number </span>

              <v-text-field
                outlined
                v-model="unitData.regNo"
                hide-details
                maxlength="10"
                placeholder="Register Number"
                dense
              ></v-text-field>
            </v-flex>

            <v-flex xs12 sm6 pa-2 align-self-start text-left>
              <span style="font-family: poppinsbold"> Description </span>

              <v-text-field
                outlined
                hide-details
                v-model="unitData.description"
                placeholder="Description"
                dense
              ></v-text-field>

              <!-- <v-textarea
                outlined
                v-model="description"
                placeholder="Description"
                dense
              ></v-textarea> -->
            </v-flex>

            <v-flex xs12 md12 pa-6>
              <v-btn
                dark
                depressed
                tile
                color="teal"
                large
                class="rounded-xl"
                @click="validateInput()"
              >
                <span style="font-family: poppinsregular; color: white">
                  SUBMIT</span
                ></v-btn
              >
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      menu: false,
      menu1: false,
      units: null,
      program: "",
      types: null,
      type: null,
      icId: null,
      icList: [],
      name: null,
      description: null,
      unitData: {
        name: null,
        description: null,
        type: null,
        icId: null,
        regNo: null,
        noOfSeats: null,
      },
    };
  },
  beforeMount() {
    this.getData();
    this.getTypes();
    this.getIc();
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var month = dt.getMonth();
      dt = dt.toString();
      var strTime = year + "-" + month + "-" + day;
      return strTime;
    },

    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/view/each/unit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.unitData = response.data.data;
          if (response.data.data.unitUnderIc) {
            this.unitData.icId = response.data.data.unitUnderIc;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getTypes() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/view/unit/types",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.types = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getIc() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/view/all/ic",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.icList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validateInput() {
      if (!this.unitData.name) {
        this.msg = "Please Provide Name";
        this.showSnackBar = true;
        return;
      } else if (!this.unitData.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.unitData.type) {
        this.msg = "Please Provide Type";
        this.showSnackBar = true;
        return;
      } else {
        this.edit();
      }
    },
    edit() {
      var allData = {};
      allData["name"] = this.unitData.name;
      allData["id"] = this.unitData._id;
      allData["description"] = this.unitData.description;
      allData["type"] = this.unitData.type;
      if (this.unitData.type != "Ic" && this.unitData.type != "Entry Point") {
        if (!this.unitData.icId) {
          this.msg = "Please Provide IC";
          this.showSnackBar = true;
          return;
        } else {
          allData["icId"] = this.unitData.icId;
        }
      }

      if (this.unitData.type == "Transport Vehicle") {
        if (!this.unitData.regNo) {
          this.msg = "Please Provide Register Number";
          this.showSnackBar = true;
          return;
        } else if (!this.unitData.noOfSeats) {
          this.msg = "Please Provide No. of seats";
          this.showSnackBar = true;
          return;
        } else {
          allData["noOfSeats"] = this.unitData.noOfSeats;
          allData["regNo"] = this.unitData.regNo;
        }
      }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/unit/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: allData,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.$router.push("/Admin/viewUnits");
          }
          // this.name = null;
          this.unitData = {};
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style>
input {
  color: black !important;
}
</style>
